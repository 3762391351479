<template>
  <div class="card">
    <div class="pb-0 card-header mb-0 d-flex justify-space-between">
      <p class="d-block" v-if="users.length > 0">
        Last active user was on
        <span class="font-500">{{
          $moment(users[users.length - 1]?.lastActive).format("LLL")
        }}</span>
        and it was
        <span class="font-500">{{ users[users.length - 1]?.name }}</span>
      </p>
      <base-select
        v-if="users.length > 0 && !$vuetify.breakpoint.mobile"
        class="d-block"
        :key="reloadDate"
        :items="dates"
        placeholder="Select Date"
        v-model="date"
        item-value="value"
        itemText="title"
        :hideDetails="false"
        :returnObject="false"
        :rules="[]"
        @input="dateChanged"
      ></base-select>
    </div>
    <div class="pa-3 card-body">
      <div class="chart" v-if="!$vuetify.breakpoint.mobile">
        <canvas
          ref="chart"
          id="chart-line"
          class="chart-canvas"
          height="150"
        ></canvas>
      </div>
      <div v-else>
        <v-row>
          <v-col
            cols="12"
            md="12"
            v-for="user in [...users].reverse()"
            :key="user._id"
          >
            <div class="pa-3 d-flex" @click="fetchItemByID(user._id)">
              <div class="d-block">
                <img
                  v-if="user.google"
                  class="user-avatar"
                  :src="user.google.picture"
                  alt=""
                />
                <img v-else class="user-avatar" :src="avatar" alt="" />
              </div>
              <div class="d-block ml-3 mt-1">
                <p class="my-0 font-15">{{ user.name }}</p>
                <p class="my-0 font-13 gray--text">
                  {{ $moment(user.lastActive).format("LLL") }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-dialog
        v-model="userDialog"
        max-width="450px"
        transition="scale-transition"
      >
        <div class="white pa-5">
          <user-details
            :item="user"
            style="overflow-y: auto; height: calc(100vh - 200px)"
          />
          <v-col cols="12" style="height: 50px">
            <!-- buttons -->
            <buttons-experience
              @cancel="close"
              cancelText="OK"
              text=""
            ></buttons-experience>
          </v-col>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import UserDetails from "@/components/User/UserDetails.vue";
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  name: "active-users-chart",
  components: {
    UserDetails,
    ButtonsExperience
  },
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    users: {
      handler() {
        this.getUsers();
      }
    }
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    chart: undefined,
    intervalID: undefined,
    userDialog: false,
    user: undefined,
    date: null,
    dates: [],
    reloadDate: false
  }),
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    dateChanged() {
      this.getUsers();
    },
    async getUsers() {
      this.dates = this.users.reduce((result, user) => {
        if (
          !result.some(
            (item) => item.title == this.$moment(user.lastActive).format("LL")
          )
        ) {
          result.unshift({
            title: this.$moment(user.lastActive).format("LL"),
            value: this.$moment(user.lastActive)
          });
        }
        return result;
      }, []);
      this.dates.unshift({ title: "All", value: null });
      const filteredUsers = this.users.reduce((result, user) => {
        if (
          !this.date ||
          this.$moment(user.lastActive).format("LL") ==
            this.$moment(this.date).format("LL")
        ) {
          result.push({
            x: this.$moment(user.lastActive),
            y: Number(
              this.$moment(user.lastActive).format("HH:mm").split(":").join("")
            ),
            user: user
          });
        }
        return result;
      }, []);
      if (!this.chart) {
        await this.reloadChart();
      }
      if (this.chart) {
        this.chart.data.datasets[0].data = filteredUsers;
        this.chart.data.datasets[0].pointStyle = await getImages(
          filteredUsers.map((item) => item.user)
        );
        xAxisLabels = [];
        this.chart?.update();
      }
    },
    async fetchItemByID(userID) {
      if (userID) {
        let { data } = await this.$axios.get(`/users/${userID}`);

        if (data) {
          this.user = data.data;
          this.userDialog = true;
        }
      }
    },
    close() {
      this.userDialog = false;
    },
    async reloadChart() {
      if (!this.$vuetify.breakpoint.mobile && this.$refs.chart) {
        const ctx = this.$refs.chart.getContext("2d");
        const moment = require("moment");
        this.chart = new Chart(ctx, {
          type: "scatter",
          data: {
            datasets: [
              {
                label: "Last Active Users",
                data: [],
                borderColor: "rgba(75, 192, 192, 1)",
                tension: 0.5,
                pointRadius: 20,
                hoverRadius: 20,
                pointBorderColor: "rgba(75, 192, 192, 1)",
                pointStyle: []
              }
            ]
          },
          options: {
            animation: false,
            legend: {
              display: false
            },
            scales: {
              x: {
                position: "bottom",
                beginAtZero: false,
                ticks: {
                  type: "linear",
                  maxTicksLimit: 1000,
                  callback: function (value) {
                    const date = moment(value).format("LL");
                    if (xAxisLabels.includes(date)) {
                      return moment(value).format("hh:mm A");
                    } else {
                      xAxisLabels.push(date);
                      return date;
                    }
                  }
                },
                grid: {
                  display: false,
                  drawBorder: true,
                  color: function (context) {
                    if (xAxisLabels.includes(context.tick.label)) {
                      return "black";
                    } else {
                      return "lightGray";
                    }
                  }
                }
              },
              y: {
                beginAtZero: false,
                ticks: {
                  callback: function (value) {
                    const date = new Date();
                    const hours = Math.floor(value / 100);
                    const minutes = value % 100;
                    date.setHours(hours);
                    date.setMinutes(minutes);
                    const options = {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true
                    };
                    return date.toLocaleTimeString("en-US", options);
                  }
                }
              }
            },
            onClick: function (event, elements) {
              if (elements.length > 0) {
                const chartElement = elements[0];
                const index = chartElement.index;
                this.data.datasets[0].fetchUser(
                  this.data.datasets[0].data[index].user._id
                );
              }
            },
            plugins: {
              legend: {
                display: false
              },
              elements: {
                point: {
                  hoverBackgroundColor: "transparent",
                  hoverBorderColor: "transparent",
                  hoverBorderWidth: 0
                }
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `${
                      context.dataset.data[context.dataIndex].user.name || ""
                    }: ${moment(
                      context.dataset.data[context.dataIndex].user.lastActive
                    ).format("LLLL")}`;
                  },
                  title: function () {
                    return "";
                  }
                }
              }
            }
          }
        });
        this.chart.data.datasets[0].fetchUser = this.fetchItemByID;
      }
    }
  },
  async created() {
    await this.getUsers();
  },
  mounted() {}
};
let xAxisLabels = [];
function loadImageAsync(url) {
  return new Promise((resolve) => {
    createRoundedImage(url, 40)
      .then((roundedImage) => {
        resolve(roundedImage);
      })
      .catch(() => {
        resolve("");
      });
  });
}
function createRoundedImage(imageUrl, diameter) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = diameter;
      canvas.height = diameter;
      ctx.beginPath();
      ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, Math.PI * 2);
      ctx.clip();
      ctx.closePath();
      ctx.drawImage(img, 0, 0, diameter, diameter);
      const dataUrl = canvas.toDataURL("image/png");
      const roundedImage = new Image();
      roundedImage.src = dataUrl;

      roundedImage.onload = () => {
        resolve(roundedImage);
      };
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
}
async function getImages(users) {
  let images = [];
  try {
    images = await Promise.all(
      users
        .map((user) => {
          return user.google?.picture || "";
        })
        .map((url) => loadImageAsync(url))
    );
    return images;
  } catch (error) {
    return [];
  }
}
</script>
<style scoped>
.card {
  .card-header {
    height: 100px;
  }
  .card-body {
    overflow: hidden;
    .user-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
