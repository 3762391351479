<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <p> <span class="font-20 font-500">Total Active users: </span> <span class="font-22 green--text font-500">{{ this.users.length }}</span></p>
      <p>Daily Activity</p>
    </div>
    <div class="pa-3 card-body">
      <div class="chart">
        <canvas
          ref="chart"
          id="chart-line"
          class="chart-canvas"
          height="150"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "active-users-chart",
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    users: {
      handler() {
        this.getUsers();
      }
    }
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    chart: undefined,
    dataSet: [],
    groups: []
  }),
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    listDatesFrom(startDate) {
      const start = this.$moment(startDate).startOf("day");
      const end = this.$moment().startOf("day");
      const dates = [];

      for (let date = start; date.isSameOrBefore(end); date.add(1, "days")) {
        dates.push(date.format("L"));
      }

      return dates;
    },
    async getUsers() {
      if (this.users.length > 0) {
        const dates = this.listDatesFrom(
          this.$moment(this.users[0].lastActive)
        );
        if (this.chart) {
          this.groups = dates.reduce((groups, date) => {
            if (!groups[date]) {
              groups[date] = [];
            }
            const filteredUsers = this.users.filter((user) => {
              return this.$moment(user.lastActive).format("L") === date;
            });
            groups[date] = filteredUsers;
            return groups;
          }, {});
          this.chart.data.datasets[0].data = Object.entries(this.groups).map(
            ([date, group]) => {
              return {
                x: date,
                y: group.length
              };
            }
          );
          this.chart?.update();
        }
      }
    }
  },
  async created() {
    await this.getUsers();
  },
  async mounted() {
    const ctx = this.$refs.chart.getContext("2d");
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Active Users",
            data: this.dataSet,
            borderColor: "rgba(75, 192, 192, 1)",
            tension: 0.5
          }
        ]
      },
      options: {
        animation: false,
        scales: {
          x: {
            beginAtZero: false
          },
          y: {
            beginAtZero: false
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
};
</script>
<style scoped>
.card {
  .card-header {
    overflow: hidden;
  }
  .card-body {
    overflow: hidden;
  }
}
</style>
