<template>
  <div class="homepage pa-7">
    <v-row>
      <v-col cols="12" md="12">
        <daily-usage-chart :users="users"></daily-usage-chart>
      </v-col>
      <v-col cols="12" md="12">
        <active-users-chart :users="users"></active-users-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActiveUsersChart from "./Chart/ActiveUsers.vue";
import DailyUsageChart from "./Chart/DailyUsage.vue";

export default {
  components: { ActiveUsersChart, DailyUsageChart },
  data: () => ({
    users: [],
    intervalID: undefined
  }),
  beforeDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  methods: {
    async getUsers() {
      let { data } = await this.$axios.get(`users/all/new?pageSize=`);
      if (data) {
        this.users = data.data
          .filter((user) => user.lastActive)
          .sort((a, b) => new Date(a.lastActive) - new Date(b.lastActive));
      }
    }
  },
  async created() {
    this.getUsers()
    this.intervalID = setInterval(() => {
      this.getUsers();
    }, 5000);
  }
};
</script>

<style lang="scss">
.homepage {
  background: #fbfbfd;
}
</style>
